import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import Breadcrumb from '../../../../components/Breadcrumb';

const AdvanceProcessing = ({pageContext}) => (
	<Layout>
		<SEO title="Advance processing" />
		<Hero>
			<Breadcrumb crumbs={pageContext?.crumbs} />
			<h1>Advance processing</h1>
			<p></p>
		</Hero>
		<Block light>
			<Content>
				<Subnavigation>
					{/*<Anchors>*/}
					{/*	<a href={'#advance-processing'}>Advance processing</a>*/}
					{/*</Anchors>*/}

					<PersonBadge
						note={pageContext.service.contactNote}
						email={pageContext.service.contactPerson}
					/>
				</Subnavigation>
			</Content>
		</Block>
		<Block light>
			<Content>
				<p>
					The volume of satellite EO data is rising rapidly, never before have
					so many EO missions been providing observation of our planet. These
					vast EO collections require new and innovative approaches to data
					management, processing and analysis. The conventional approach of
					local data storage and in-house processing is no longer feasible. More
					viable solutions take advantage of distributed storage and computing
					resources that are co-located with the EO data archives. Cloud
					computational infrastructures combined with scalable analytical
					platform capabilities represent a modern viable solution for
					operational EO data processing and analysis.
				</p>
				<p>
					To respond to these challenges we have completely redesigned and
					upgraded our EO data processing workflows. We have developed our
					internal production framework that relies on open-source based, cloud
					agnostic and scalable solution. It fully supports distributed
					computing and server clustering enabling high availability, load
					balancing and parallel processing. Standalone processing tools
					dedicated to particular processing and analytical tasks are developed
					using the microservice architecture. Large scale production is
					possible thanks to the big data concept that is inherited in the
					system.
				</p>
				<p>
					Robust, proven and operational processing chains are implemented
					covering diverse approaches for integrated analysis of optical & SAR
					satellite imagery. They are based on the use of state-of-the-art
					analytical methods, such as artificial intelligence (machine & deep
					learning, neural networks), decision trees or object-based image
					analysis. The EO data pre-processing and integration are centralized
					to generate Analysis Ready Data (ARD). The EO data analyses are
					supported by additional expertise, such as geospatial statistics,
					feature extraction, data mining or area frame sampling.
				</p>
			</Content>
		</Block>
	</Layout>
);

AdvanceProcessing.propTypes = {
	pageContext: PropTypes.object.isRequired,
};

export default AdvanceProcessing;
